:root {
  --primary-color: #669bbc;
  --secondary-color: #003049;
}

a {
  text-decoration: none;
  color: #000;
}

html{
  background-color:var(--primary-color) ;
  background-image: url("../public//shb.jpg");
  background-size:cover;
  background-repeat: no-repeat;
}

body {
  margin: 0; /* Remove default body margin for responsiveness */
}

.centered-container {
  display: flex;
  flex-direction: column; /* Adjusted to column for responsiveness */
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.centered-container button:hover{
  background-color: var(--primary-color);
  color: var(--secondary-color);
  box-shadow: 20px rgba(0, 0, 0, 1);
}

/* Additional styling for buttons, if needed */
.centered-container button {
  margin: 5px;
  border-radius: 10px;
  width: 100%;
  min-height: 40px;
  max-height: 65px;
  border: 1px var(--secondary-color) solid;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  font-weight: bolder;
}

.userForm {
  display: flex;
  flex-direction: column; /* Adjusted to column for responsiveness */
  align-items: center;
  justify-content: center;
  min-width: 140px;
  min-height: 150px;
  max-height: 350px;
  border: 2px solid;
  border-radius: 10px;
  padding: 10px;
  color: var(--secondary-color);
  background-color: var(--primary-color);
}

.userform .logo{
  position: fixed;
  top: 20%;
  left: 46%;
  
}

.userForm input {
  border: 0;
  width: 100%;
  min-height: 30px;
  border-radius: 5px;
}

.totp-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  min-width: 450px;
  min-height: 350px;
  max-height: 350px;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  padding: 20px;
  box-shadow: 20px rgba(0, 0, 0, 0.8);
  z-index: 9999;
  border: 2px solid;
  border-radius: 10px;
}

.home{
  position: fixed;
  top: 50%;
  left: 50%;
  min-height: 300px;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  padding: 20px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.home button{
  margin-top: 10px;
  margin: 5px;
  border: 0px;
  border-radius: 10px;
  width: 100%;
  min-height: 40px;
  max-height: 65px;
  border: 1px var(--secondary-color) solid;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  font-weight: bolder;
}

.mapOC{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eachFamily {
  list-style: none;
  padding: 5px;
  margin: 2px;
  border: var(--secondary-color) 2px solid;
  border-radius: 10px;
}

nav{
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--primary-color);
  text-align: center;
  font-weight: bolder;
}

nav button {
  margin: 5px;
  border-radius: 10px;
  min-width: 100px;
  min-width: 130px;
  min-height: 30px;
  max-height: 40px;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border: 2px solid var(--primary-color);
}

.filter{
  min-width: 110px;
  margin: 10px;
}

select{
  min-width: 100px;
  max-width: 200px;
  min-height: 25px;
  border: 0px;
  border-radius: 5px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-weight: bolder;
}

.sydneyJamaatBanner {
  height: 200px;
}

/* Media query for tablet/desktop screens */
@media screen and (min-width: 768px) {
  .centered-container {
    flex-direction: row;
  }

  .mapOC{
    flex-direction: row;
  }
}
